import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";
import { colors, device } from ".";

const BUTTON_MODIFIERS = {
   green: () => `
        background: ${colors.green100};
        color: ${colors.black};
   `,
   lg: () => `
    padding: 16px 24px;

    @media ${device.tablet} {
        padding: 24px 40px;
    }
   `,
}

const baseButtonStyles = css`
    padding: 16px 32px;
    background: #000;
    border: none;
    color: #FFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
`

export const PrimaryCTA = styled.a`
    ${baseButtonStyles};
    ${applyStyleModifiers(BUTTON_MODIFIERS)}
`

export const AnchorScrollCTA = styled(Link)`
    ${baseButtonStyles};
    ${applyStyleModifiers(BUTTON_MODIFIERS)}
`

export const FormButton = styled.button`
    ${baseButtonStyles};
    ${applyStyleModifiers(BUTTON_MODIFIERS)}
`