import styled from "styled-components";
import { Container, device } from "../../styles";

export const TwoColWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 10px;

    @media ${device.tablet} {
        flex-direction: row;
        padding-bottom: 80px;
        padding-top: 0px;

        .gatsby-image-wrapper {
            margin-left: 40px;
        }
    }

    &.reverse-col {
        @media ${device.tablet} {
            flex-direction: row-reverse;

            .gatsby-image-wrapper {
                margin-left: 0px;
                margin-right: 40px;
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;

        @media ${device.tablet} {
            justify-content: center;
            width: 50%;
        }
    }

    h2 {
        margin-top: 24px;
        text-transform: uppercase;

        @media ${device.tablet} {
            margin-top: 0;
        }
    }

    p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.618;


        @media ${device.tablet} {
            font-size: 24px;
        }
    }
`