import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Banner from '../components/Banner'
import Form from '../components/Form/Form'
import InstagramFeed from '../components/InstagramFeed/InstagramFeed'
import Layout from '../components/Layout'
import Section from '../components/Section/Section'
import TwoCol from '../components/TwoCol/TwoCol'
import PlainTextBody from '../components/PlainTextBody'
import SectionHeading from '../components/SectionHeading/SectionHeading'
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  {
    imageOne: allFile(
      filter: {
        absolutePath: {
          regex: "/images/"
        }, 
      relativePath: {
        eq: "residential-junk-haul-services-in-maine.jpg"
        }}
    ) {
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(
            formats: AUTO,
            layout: FULL_WIDTH,
            quality: 100,
          )
        }
      }
    }
    imageTwo: allFile(
      filter: {absolutePath: {regex: "/images/"}, relativePath: {eq: "commercial-junk-haul-services-in-maine.jpg"}}
    ) {
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(
            formats: AUTO,
            layout: FULL_WIDTH,
            quality: 100,
          )
        }
      }
    }
    formImg: allFile(
      filter: {absolutePath: {regex: "/images/"}, relativePath: {eq: "truck-photo.jpg"}}
    ) {
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(
            formats: AUTO,
            layout: FULL_WIDTH,
            quality: 100,
          )
        }
      }
    }
    bannerImage: allFile(
      filter: {absolutePath: {regex: "/images/"}, relativePath: {eq: "banner-fpo.jpg"}}
    ) {
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(
            formats: AUTO,
            layout: FULL_WIDTH,
            quality: 100,
          )
        }
      }
    }
  }
  `)

  const bannerImageSrc = data.bannerImage.nodes[0].childImageSharp.gatsbyImageData;
  const imagePathOne = data.imageOne.nodes[0].childImageSharp.gatsbyImageData;
  const imagePathTwo = data.imageTwo.nodes[0].childImageSharp.gatsbyImageData;
  const formImgPath = data.formImg.nodes[0].childImageSharp.gatsbyImageData;

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Southern Maine's Junk Removal Service</title>
        <meta name="description" content="Big Junk Haul is a full service and insured junk removal company. 
        We handle both residential and commercial customers." />
        <link rel="canonical" href="https://bigjunkhaul.com/" />
        <meta name="google-site-verification" content="sMJiFRxqEW09EnECwyk8YlbvJpWrtwxnHumI9Zy6D1E" />
        <meta name="msvalidate.01" content="3AEB42F949A3F082853B9F688701A3FC" />
    </Helmet>
    <Layout>
      <Banner
        heading={'Southern Maine’s Premiere Junk Removal Service'}
        bgImage={bannerImageSrc}
      />
      <PlainTextBody
        body={`Big Junk Haul is a full service and insured junk removal company. 
        We handle both residential and commercial customers and take pride in providing 
        “white glove” removal services. We provide thorough clean up and all jobs are swept 
        or raked clean. We also offer off hour and weekend services.`}
        bgColor
      />
      <SectionHeading
        centered
        heading={`Our Services`}
      />
      
      <TwoCol
        flipped
        headline={`Residential`}
        imageSrc={imagePathOne}
        body={`Do you have a garage, basement or attic that is packed full and needs to be purged? 
        This task is often overwhelming for the homeowner. We are ready and able to help with your junk 
        removal needs. We also offer light demolition services such as shed or swing set demolition 
        and removal.`}
      />
      
      <TwoCol
        headline={`Commercial`}
        imageSrc={imagePathTwo}
        body={`We offer junk removal for businesses whether it be a construction site, store, office, 
        storage unit, rental unit or whole house clean out. We offer one time pick up or ongoing removal 
        services for our commercial clients.`}
      />
      <Section>
        <TestimonialsSlider />
      </Section>
      <Section lightBackground id={`form`}>
        <Form formImage={formImgPath} />
      </Section>
      <Section>
        <InstagramFeed />
      </Section>
    </Layout>
    </>
  )
}

export default IndexPage
