import React from 'react'
import { Container } from '../../styles'
import { BodyWrapper } from './PlainTextStyles'

const PlainTextBody = ({
    body,
    bgColor,
}) => {
    return (
        <BodyWrapper className={`${bgColor ? 'white' : ''}`}>
            <Container>
                <p>{body}</p>
            </Container>
        </BodyWrapper>
    )
}

export default PlainTextBody
