import styled from "styled-components";
import { colors, device } from "../../styles";

export const BodyWrapper = styled.div`
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    line-height: 1.618;
    text-align: center;

    @media ${device.tablet} {
        padding: 80px 0 60px;
    }

    &.white {
        background: ${colors.black};
        color: ${colors.white};
    }

    p {
        margin: 0 auto;
        max-width: 840px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.618;


        @media ${device.mobileM} {
            font-size: 24px;
        }
    }
`