import styled from "styled-components";
import { colors, Container, device } from "../../styles";

export const BannerMain = styled.section`
    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 1;

    @media ${device.tablet} {
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 100px;
            width: 1px;
            background: ${colors.green100};
            content: "";
        }
    }

    ${Container} {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
`
export const BannerCopy = styled.div`
    color: ${colors.white};
    text-align: center;
`

export const BannerImage = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .gatsby-image-wrapper {
        height: 100%;
    }
`

export const BannerHeading = styled.h1`
    margin-bottom: 56px;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media ${device.mobileM} {
        font-size: 32px;
        letter-spacing: 2px;
    }

    @media ${device.tablet} {
        max-width: 800px;
        font-size: 56px;
    }
`