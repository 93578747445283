import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import uniqueId from 'uniqid'
import 'swiper/css'
import 'swiper/css/navigation'
import { Container } from '../../styles'
import { SliderBody, SliderHeader, SliderWrapper, StarContainer } from './TestimonialsSliderStyles'
import { IoStarSharp } from 'react-icons/io5'
import SwiperCore, {
    Autoplay,
    Navigation
} from 'swiper';

SwiperCore.use([Autoplay, Navigation])

const TestimonialsSlider = () => {
    const reviews = [
        {
            review: 'Thank you for making it painless, pleasant and most of all hassle free! Thanks Big Junk Haul! I wish I would have thought of them first. We have no regrets!'
        },
        {
            review: 'Would definitely recommend Big Junk Haul and we will definitely be using them again in the future.'
        }
    ]

    const fiveStars = 5;

    return (
        <SliderWrapper>
            <Container>
                <SliderHeader>
                    <h3>Hear What Our Customers Are Saying</h3>
                </SliderHeader>
                <SliderBody>
                    <Swiper
                        autoplay={{
                            "delay": 5000,
                            "disableOnInteraction": false
                        }}
                        loop={true}
                        navigation={true}
                        slidesPerView={1}
                    >
                        {reviews.map(reviewItem => {
                            return (
                                <SwiperSlide key={uniqueId('bjh')}>
                                    <p>{reviewItem.review}</p>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </SliderBody>
                <StarContainer>
                    {[...Array(fiveStars)].map((e, i) => 
                        <IoStarSharp />
                    )}
                </StarContainer>
            </Container>
        </SliderWrapper>
    )
}

export default TestimonialsSlider
