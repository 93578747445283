import React from 'react'
import { Container, Logo } from '../../styles/GlobalStyles'
import { HeaderMain } from './HeaderStyles'
import LogoFile from '../../images/big-junk-haul.svg'

const Header = () => {
    return (
        <HeaderMain>
            <Container>
                <Logo to={`/`}>
                    <img src={`${LogoFile}`} alt={`Big Junk Haul Logo`} />
                </Logo>
                <a href={`tel:2073373133`}>(207) 337-3133</a>
            </Container>
        </HeaderMain>
    )
}

export default Header
