import styled from "styled-components";
import { device } from "../../styles";

export const InstaFeed = styled.section`
    margin: 0 auto;
    max-width: 90%;

    h2 {
        margin: 0 0 24px;
        text-align: center;
    }
`

export const Instagrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #FFF;

`

export const IgPost = styled.div`
    max-height: 180px;
    width: 50%;

    @media ${device.tablet} {
        max-height: 200px;
        width: 25%;
    }

    @media ${device.laptop} {
        max-height: 250px;
    }

    .gatsby-image-wrapper {
        height: 100%;

        img {
            object-fit: cover;
            height: 100%;
        }
    }

`