import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { AnchorScrollCTA } from '../../styles/ButtonStyles'
import { Container } from '../../styles/GlobalStyles'
import { BannerCopy, BannerHeading, BannerImage, BannerMain } from './BannerStyles'

const Banner = ({
    heading,
    bgImage,
}) => {
    return (
        <BannerMain>
            <BannerImage>
                <GatsbyImage image={bgImage} alt={`Banner background`} />
            </BannerImage>
            <Container>
                <BannerCopy>
                    <BannerHeading>
                        {heading}
                    </BannerHeading>
                    <AnchorScrollCTA
                        modifiers={['green', 'lg']}
                        smooth={true}
                        to={`form`}
                    >
                        Get an estimate now
                    </AnchorScrollCTA>
                </BannerCopy>
            </Container>
        </BannerMain>
    )
}

export default Banner
