import React from 'react'
import { TwoColWrapper } from './TwoColStyles'
import { GatsbyImage } from 'gatsby-plugin-image'

const TwoCol = ({
    body,
    flipped,
    headline,
    imageSrc,
}) => {
    return (
        <TwoColWrapper className={`${flipped ? 'reverse-col' : ''}`}>
            <div className={`col`}>
                <h2>{headline}</h2>
                <p>
                    {body}
                </p>
            </div>
            <div className={`col`}>
                {imageSrc && (
                    <GatsbyImage
                        image={imageSrc}
                        alt={``}
                    />
                )}
            </div>
        </TwoColWrapper>
    )
}

export default TwoCol
