import styled from "styled-components";
import { colors, Container, device } from "../../styles";

export const SliderWrapper = styled.section`
    ${Container} {
        position: relative;
    }
`

export const SliderHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

        h3 {
            font-size: 22px;
            text-transform: uppercase;
        }
`

export const SliderBody = styled.div`
    text-align: center;

    p {
        margin: 12px auto 0;
        max-width: 720px;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.618;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;

        @media ${device.tablet} {
            display: inline-block;
            color: ${colors.black};
            transition: linear 0.35s;

            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    color: ${colors.green100};
                }
            }
        }
    }
`

export const StarContainer = styled.div`
    margin: 24px auto 0;
    width: 150px;

    svg {
        height: 30px;
        width: 30px;
    }
`
