import styled, { createGlobalStyle } from 'styled-components'
import normalize from 'styled-normalize'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { Link } from 'gatsby'
import { colors, device } from '.'

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    @mixin aspect-ratio-media($width, $height) {
        position: relative;

        &:before {
            display: block;
            content: '';
            width: 100%;
            padding-top: ($height / $width) * 100%;
        }

        [data-media],
        .gatsby-image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    html {
        box-sizing: border-box;
        -webkit-text-size-adjust: 100%;
        font-family: system-ui, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        touch-action: manipulation;
        line-height: 1.5;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        background: ${colors.white};
        color: ${colors.black};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    img {
        height: auto;
        width: 100%;
    }

    ::selection {
        background: ${colors.green100};
        color: ${colors.black};
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #666;
    }
`

const CONTAINER_MODIFIERS = {
    flex: () => `
        display: flex;
    `,
    alignCenter: () => `
        align-items: center;
    `,
    spaceBetween: () => `
        justify-content: space-between;
    `,
}

export const Container = styled.div`
    margin: 0 auto;
    max-width: 1400px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;

    ${applyStyleModifiers(CONTAINER_MODIFIERS)}
`

export const Logo = styled(Link)`
    display: inline-block;
    height: 60px;
    width: 50px;

    @media ${device.tablet} {
        height: 90px;
        width: 80px;
    }
`