import styled from "styled-components";
import { colors, device } from "../../styles";
import { applyStyleModifiers } from 'styled-components-modifiers'

const SECTION_MODIFIERS = {
    noTopPadding: () => `
        padding-top: 0;
    `,
}

export const SectionWrapper = styled.section`
    padding: 40px 0;

    &.light {
        background: #f8f8f9;
        color: ${colors.black};
    }

    @media ${device.tablet} {
        padding: 80px 0;
    }

    ${applyStyleModifiers(SECTION_MODIFIERS)}
`