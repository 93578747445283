import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from '../../styles'
import { FormButton } from '../../styles/ButtonStyles'
import {
    FormBody,
    FormContainer,
    FormImage,
    FormHeader,
} from './FormStyles'

const Form = ({
    formImage,
}) => {
    return (
        <FormContainer>
            <Container>
                <FormBody>
                    <FormHeader>
                        <h2>Have a question or looking for an estimate?</h2>
                        <p>Fill out the form below and we will get back to you shortly or give us a call at <a href={`tel:2073373133`}>(207) 337-3133</a>.</p>
                    </FormHeader>
                    <form name="contact" method="POST" data-netlify="true">
                        <div>
                            <label>Name <input type="text" name="name" /></label>   
                        </div>
                        <div>
                            <label>Email address <input type="email" name="email" /></label>
                        </div>
                        <div>
                            <label>Phone number <input type="tel" name="tel" /></label>
                        </div>
                        <div>
                            <label>Message <textarea name="message"></textarea></label>
                        </div>
                        <input type="hidden" name="form-name" value="contact" />
                        <div>
                            <FormButton
                                type="submit"
                                className={`submit`}
                                modifiers={['green']}
                            >
                                Send Inquiry
                            </FormButton>
                        </div>
                    </form>
                </FormBody>
                <FormImage>
                    <GatsbyImage image={formImage} />
                </FormImage>
            </Container>
        </FormContainer>
    )
}

export default Form
