import React from 'react'
import { Container } from '../../styles/GlobalStyles'
import { FooterMain } from './FooterStyles'

const Footer = () => {
    return (
        <FooterMain>
            <Container modifiers={['flex']}>
                <p>
                    {`© ${new Date().getFullYear()} // Big Junk Haul, LLC`}
                </p>
                <p>
                    <a
                        href={`https://werra.io`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                    >
                        Site Credits
                    </a>
                </p>
            </Container>
        </FooterMain>
    )
}

export default Footer
