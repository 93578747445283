import styled from "styled-components";
import { device } from "../../styles";

export const SectionHeadingWrapper = styled.h2`
    padding: 24px 0;
    font-size: 40px;
    text-transform: uppercase;

    @media ${device.tablet} {
        padding: 80px 0;
    }

    &.centered {
        
        text-align: center;
    }
`