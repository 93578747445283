import styled from "styled-components";
import { colors, Container, device } from "../../styles";

export const FooterMain = styled.footer`
    padding: 24px 0;
    background: #f8f8f9;

    ${Container} {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media ${device.tablet} {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    p {
        margin: 0 0 8px;
        @media ${device.tablet} {
            margin: 0;
        }

        a {
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 1px solid transparent;
        }

        @media(hover: hover) and (pointer: fine) {
            a:hover {
                border-bottom: 1px solid ${colors.black}
            }
        }
    }
`