import { graphql, useStaticQuery } from 'gatsby'

const useInstagram = () => {
    const data = useStaticQuery(graphql`
        {
            allInstagramContent(limit: 4) {
                nodes {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED,
                                placeholder: BLURRED
                            )
                        }
                    }
                    caption
                    username
                    permalink
                }
            }
        }
    `)

    return data.allInstagramContent.nodes.map(node => ({
        ...node.localFile.childImageSharp,
        id: node.id,
        caption: node.caption,
        username: node.username,
    }))
}

export default useInstagram;