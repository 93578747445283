import React from 'react'
import { GlobalStyles } from '../styles/GlobalStyles'
import Footer from './Footer/Footer'
import Header from './Header/Header'

const Layout = ({ children }) => {
    return (
        <div className={`page`}>
            <GlobalStyles />
            <Header />
            <main id={`main`}>
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout
