import React from 'react'
import { SectionWrapper } from './SectionStyles'

const Section = ({
    children,
    id,
    modifiers,
    lightBackground,
}) => {
    return (
        <SectionWrapper
            modifiers={['modifiers']}
            className={`${lightBackground ? 'light' : ''}`}
            id={id}
        >
            {children}
        </SectionWrapper>
    )
}

export default Section
