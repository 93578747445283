import styled from "styled-components";
import { colors, Container } from "../../styles";

export const HeaderMain = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px 0;
    width: 100%;
    z-index: 2;

    ${Container} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a {
        color: ${colors.green100};
        font-size: 20px;
        font-weight: 700;
    }
`