import styled from "styled-components";
import { Container, device } from "../../styles";

export const FormContainer = styled.div`
    form {
        max-width: 660px;
        width: 100%;

        div {
            display: flex;
        }

        textarea,
        input:not(.submit) {
            margin-top: 12px;
            padding: 12px 6px;
            width: 100%;
        }

        label {
            display: block;
            margin-bottom: 12px;
            width: 100%;
        }

        textarea {
            height: 200px;
            resize: none;
        }
    }

    ${Container} {
        display: flex;
        flex-direction: column;

        @media ${device.tablet} {
            flex-direction: row;
        }
    }
`

export const FormBody = styled.div`
    @media ${device.tablet} {
        width: 50%;
    }
`

export const FormImage = styled.div`
    margin-top: 24px;

    @media ${device.tablet} {
        margin-top: 0;
        padding-left: 40px;
        width: 50%;
    }

    .gatsby-image-wrapper {
        height: 100%;
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        padding-top: 14px;
    }
`

export const FormHeader = styled.header`
    h2 {
        margin: 0;
        max-width: 580px;
        font-size: 28px;
        font-weight: 600;
        text-transform: uppercase;

        @media ${device.mobileM} {
            font-size: 32px;
        }
    }

    p {
        margin: 8px 0 40px;
        font-size: 18px;
        line-height: 1.618;

        @media ${device.mobileM} {
            font-size: 20px;
        }

        @media ${device.tablet} {
            font-size: 24px;
        }
    }
`