import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import uniqueId from 'uniqid'
import useInstagram from '../../hooks/use-instagram';
import { Instagrid, IgPost, InstaFeed } from './InstagramFeedStyles';
import { Container } from '../../styles';

const InstagramFeed = () => {
    const instaPosts = useInstagram();
    const { username } = instaPosts[0]
    return (
        <InstaFeed>
            <Container>
                <h2>
                    <a
                        href={`https://instagram.com/${username}/`}
                        target={`_blank`}
                    >
                        {`Follow us @${username}`}
                    </a>
                </h2>
                <Instagrid>
                    {instaPosts.map(post => (
                        <IgPost key={uniqueId('bjh')}>
                            <GatsbyImage image={post.gatsbyImageData} alt={`Instagram posts from ${username}`} />
                        </IgPost>
                    ))}
                </Instagrid>
            </Container>
        </InstaFeed>
    )
}

export default InstagramFeed;
