import React from 'react'
import { SectionHeadingWrapper } from './SectionHeadingStyles'

const SectionHeading = ({
    heading,
    centered,
}) => {
    return (
        <SectionHeadingWrapper className={`${centered ? "centered" : ""}`}>
           {heading}
        </SectionHeadingWrapper>
    )
}

export default SectionHeading
